<template>
  <div class="main">
    <div class="content">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">图书搜索</div>
        <div class="gaoji" @click="seniorMethod()">高级搜索</div>
      </div>
      <!-- 关键字 -->
      <div class="keyword">{{'搜索“'+title+'”，'+'搜索到'+totalNumber+'个结果'}}</div>
      <!-- 内容列表 -->
      <div class="list">
        <div class="list-view">
          <bookList v-for="(item,index) in List" :key="index" :index="index" :item="item"></bookList>
        </div>
        <div class="page" v-show="pageShow">
            <div class="page1">
                <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import bookList from './components/bookSearch-box'
export default {
	components:{bookList},
	data() {
		return {
			pageShow:false,
			totalNumber:0,
			totals:1,
			currentPage:1,
			pageSize:20,
			title:'',
			List:[],
		}
	},
	created(){
	  this.title = this.$route.query.title
		this.$store.commit('changClick',1)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
		  this.$store.commit('changZSkin',zskin)
		}
		this.$store.commit('changSearch',1)
		bus.$on('message', (title) => {
			this.title = title
		    console.log('内容',title)
			this.getBookSearch()
		})
    this.getBookSearch()
		this.handleCurrentChange(1)
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
		isSearch(){
			return this.$store.state.isSearch
		}, 
	},
	methods:{
		/**搜索**/ 
		getBookSearch(){
			var that = this
			that.$api.getBookSearch({
				params:{
					type:1,
					author:'',
					bookNumber:'',
					compile:'',
					keyword:that.title,
					name:'',
					pageNum:that.currentPage,
					pageSize:that.pageSize,
					releaseNumber:''
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.List = res.data.data.list
					that.totals = res.data.data.pages
					that.totalNumber = res.data.data.total
					if (res.data.data.list.length == 0){
						that.pageShow = false
					}else {
						that.pageShow = true
					}
				}else{
					that.List = []
					that.totalNumber = 0
					that.pageShow = false
				}
			})
		},
		/**高级搜索**/ 
		seniorMethod(){
			this.$router.push({path:'/advancedSearch',query:{title:this.title}});
			this.$store.commit('changClick',1)
		},
		/**当前页按钮**/ 
		handleCurrentChange(index) {
			console.log(index);
			this.currentPage = index;
			this.getBookSearch()
			this.$nextTick(function () {
				/**更换分页按钮皮肤**/
				var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
				if(array && array.length>0){
					array.forEach((item,index1) => {
						if (index == array[index1].innerText) {
							array[index1].style.background  = this.isSkin;
						}else{
							array[index1].style.background  = "#f4f4f5";
						}
					})
				}
			});
		},
	}
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content{
    width: 1200px;
    .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      line-height: 55px;
      margin-top: 50px;
      display: flex;
      justify-items: center;
      align-items: center;
      .thumb-title{
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 12px;
      }
      .gaoji{
        font-size: 13px;
        line-height: 18px;  
        color: #FFFFFF;
        cursor: pointer;
        margin-left: 20px;
      }
    }
    .keyword{
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      padding: 20px;
    }
    .list{
      .list-view{
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
      }
      .page{
        width: 1200px;
        padding: 20px 20px 100px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .page1{
          display: flex;
          justify-items: center;
          align-items: center;
        }
      }
    }
  }
}
</style>